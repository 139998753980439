import React from 'react';
import './blog-item.scss';
import { Link } from 'gatsby';

class BlogItem extends React.Component {
  months = [
    'Jan',
    'Feb',
    'Maa',
    'Apr',
    'Mei',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dec'
  ];
  zeroPad(num, places) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join('0') + num;
  }
  render() {
    const { children, title, image, date, link, mobile } = this.props;
    const _date = this.zeroPad(new Date(date).getDate(), 2);
    const month = this.months[new Date(date).getMonth()];

    return (
      <Link
        className={['blog-item', mobile ? 'mobile' : 'responsive'].join(' ')}
        to={link}
      >
        <div className={'blog-item__image'}>
          <div className={'image'}>
            <img loading="lazy" src={image} alt="" />
            <div className={'date'}>
              {_date}
              <br />
              {month}
            </div>
          </div>
        </div>
        <div className={'blog-item__text'}>
          <h3>{title}</h3>
          {children}
        </div>
      </Link>
    );
  }
}

export default BlogItem;
