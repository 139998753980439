import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import BlogItem from '../components/blocks/blog-item/blog-item';
import Container from '../components/container/container';
import PageHeader from '../components/paragraphs/page-header/page-header';
import { Link, graphql } from 'gatsby';
import './inspiratie.scss';

const Blog = ({ location, data }) => {
  const langCode =
    location.pathname.split('/').filter(e => e !== '')[0] === 'en'
      ? 'en'
      : 'nl';
  return (
    <Layout location={location}>
      <SEO title="Blog" lang={langCode} />
      <PageHeader
        title={'Inspiratie'}
        colors={'Groen'}
        breadcrumbs={[{ url: '/', title: 'Home' }]}
        langCode={langCode}
      ></PageHeader>
      <div className={'block__blog-overview'}>
        <Container>
          <div className="flex">
            <div className="cat__overview">
              <h2>Categorieën</h2>
              <div className="cat__items">
                {data.allTaxonomyTermBlogCategories.nodes &&
                  data.allTaxonomyTermBlogCategories.nodes.length > 0 &&
                  data.allTaxonomyTermBlogCategories.nodes.map(
                    (item, index) => {
                      return (
                        <div className="cat__item-wrapper" key={index}>
                          <Link to={'/blog' + item.path.alias}>
                            <div className="cat__item">
                              <img
                                src={
                                  item.relationships.field_media
                                    .field_media_image.imageDerivatives.links
                                    .story_paragraph.href
                                }
                                alt={
                                  item.relationships.field_media
                                    .field_media_image.alt
                                }
                              />
                              <div className="cat__title">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item.name
                                  }}
                                ></p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
            <div className="blog__overview">
              <h2>Nieuwste blogpost</h2>
              {data.allNodeArticle.nodes &&
                data.allNodeArticle.nodes.length > 0 &&
                data.allNodeArticle.nodes
                  .reverse()
                  .slice(0, 1)
                  .map((item, index) => (
                    <BlogItem
                      key={index}
                      title={item.title}
                      date={item.created}
                      link={item.path.alias}
                      image={
                        item.relationships.field_media.field_media_image
                          .imageDerivatives.links.blog_overview.href
                      }
                      mobile
                    >
                      {item.body && item.body.summary && (
                        <p>{item.body.summary}</p>
                      )}
                    </BlogItem>
                  ))
                  .reverse()}
              <Link to={'/blog'} className={'btn big nomargin'}>
                Meer blogposts
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
};
export default Blog;

export const query = graphql`
  query Inspiratie {
    allTaxonomyTermBlogCategories {
      nodes {
        name
        path {
          alias
        }
        relationships {
          field_media {
            field_media_image {
              imageDerivatives {
                links {
                  story_paragraph {
                    href
                  }
                }
              }
              alt
            }
          }
        }
      }
    }
    allNodeArticle {
      nodes {
        title
        created
        body {
          summary
          processed
        }
        path {
          alias
        }
        relationships {
          field_media {
            field_media_image {
              imageDerivatives {
                links {
                  blog_overview {
                    href
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
